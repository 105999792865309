<template>
  <article v-loading="loading" style="background-color: #fff;">
    <ContentNav :name="name" :flag="flag" :selected="selected" @select="select"></ContentNav>
    <Pronunciation
      :id="id"
      v-if="flag === 2"
    ></Pronunciation>
    <Funny
      :id="id"
      v-else-if="flag === 9"
    ></Funny>
    <iframe class="iframe" :src="iframeSrc" frameborder="0" v-else-if="!reloadIframe"></iframe>

    <examTrolley v-if="type === 'exam'" />
    <Trolley v-else @listState="listState" />
  </article>
</template>

<script>
import ContentNav from '@/components/ContentNav.vue'
import Pronunciation from './components/Pronunciation.vue'
import Funny from '../components/Funny.vue'
import Trolley from './components/trolley.vue'
import examTrolley from '@/components/examTrolley.vue'
import utils from '@/utils/utils'
import {getUUID} from "@/utils/local";

export default {
  name: 'paperDetail',
  components: {
    ContentNav,
    Pronunciation,
    Funny,
    Trolley,
    examTrolley
  },
  data() {
    return {
      loading: false,
      id: '',
      name: '',
      flag: '',
      iframeSrc: '',
      selected: false,
      Ids: undefined,
      type: 'work',
      reloadIframe: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
  },
  created() {
    this.id = this.$route.query.id
    this.name = this.$route.query.name
    this.type = this.$route.query.type || 'work'
    this.flag = Number(this.$route.query.flag)
    this.indexModuleName = this.$route.query.indexModuleName
    this.moduleName = this.$route.query.moduleName
    this.time = this.$route.query.time
    this.moduleId = this.$route.query.moduleId
    const { selectedWorkList } = this.$store.state
    this.selected = !!selectedWorkList[this.id]

    window.addEventListener('message', this.onMessageCallback)
    const userId = this.userInfo.id
    const sessionId = sessionStorage.getItem('sessionId')
    const udid = getUUID()
    this.iframeSrc = `${this.$urls.questionPreviewUrl}?groupId=${this.id}&userId=${userId}&udid=${udid}&authToken=${sessionId}&apps=2`
    if (this.flag === 5) {
      this.loading = true
      this.fetchSimplePaperQuestions(this.id).then(({ Ids })=> {
        this.Ids = Ids
        this.loading = false
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessageCallback)
  },
  methods: {
    onMessageCallback(event) {
      const data = event.data
      if (data.from !== 'xiyou-h5-teacher') return
      if (data.type === 'error') {
        this.reloadIframe = true
        this.$nextTick(() => {
          this.reloadIframe = false
          this.iframeSrc = `${this.$urls.iframeSrc}?groupId=${this.id}`
        })
      }
    },
    select() {
      const { selected } = this
      const obj = {
        id: this.id,
        name: this.name,
        flag: this.flag,
        moduleName: this.moduleName,
        indexModuleName: this.indexModuleName,
        time: this.time,
        moduleId: this.moduleId,
        Ids: this.Ids
      }
      if (!selected) {
        this.$store.commit('listAdd', obj)
      } else {
        this.$store.commit('listDel', obj)
      }
      this.selected = !selected
    },
    listState() {
      this.selected = !!this.$store.state.selectedWorkList[this.id]
    },

    // 获取题组下的预计时长
    fetchSimplePaperQuestions(groupId) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: 'post',
          url: this.$urls.getSimplePaperQuestions,
          headers: { 'Content-Type': 'application/json' },
          data: {
            groupId
          }
        }).then(response => {
          if (response.state !== '11') {
            reject()
            return
          }
          const { data = [] } = response
          let num = 0
          let Ids = []
          data.forEach(el =>{
            num = num + utils.takeTime(el.questionsTypeId)
            if (el.id) {
              Ids.push(el.id)
            }
          })
          resolve({ time: num, Ids })
        }).catch((err) => {
          reject('err')
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
  height: calc(100vh - 196px);
}
</style>
